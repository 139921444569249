//React

import React from "react";

function Discounts() {

  React.useEffect(() => {
    document.title = process.env.REACT_APP_NAME + ' - Akciók';
  }, []);

  return (
    <section id="discounts" className="w-full max-w-viewport pl-6 pr-6 2xl:pr-0 2xl:pl-0">
      <h2 className="font-extrabold text-center text-15 lg:text-19 mb-6 ">Kéklámpás kedvezmény*</h2>
      <h2 className="font-normal text-center text-13 lg:text-15 mb-6">Hivatásos személy** vagy annak hozzátartozója részére a következő kedvezményt biztosítjuk: KRESZ tandíj teljes árából 20% kedvezmény.</h2>
      <div className="relative w-full overflow-x-auto">
        <div className="min-w-[25rem]">
          <div className="flex flex-row justify-between w-full max-w-[40rem] m-auto gap-3 lg:gap-0">
            <div className="flex flex-col items-start gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">TÍPUS</p>
              <p className="text-left font-normal text-12 lg:text-14">„B” normál</p>
              <p className="text-left font-normal text-12 lg:text-14">„B” intenzív</p>
              <p className="text-left font-normal text-12 lg:text-14">„B” automata</p>
            </div>
            <div className="flex flex-col items-center gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">VIZSGADÍJ***</p>
              <p className="text-left font-normal text-12 lg:text-14">15.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">15.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">15.600 Ft</p>
            </div>
            <div className="flex flex-col items-center gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">KRESZ ELMÉLET</p>
              <p className="text-left font-normal text-12 lg:text-14">40.000 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">40.000 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">40.000 Ft</p>
            </div>
            <div className="flex flex-col items-center gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">GYAKORLATI DÍJ</p>
              <p className="text-left font-normal text-12 lg:text-14">10.500 Ft / óra</p>
              <p className="text-left font-normal text-12 lg:text-14">12.000 Ft / óra</p>
              <p className="text-left font-normal text-12 lg:text-14">13.000 Ft / óra</p>
            </div>
            <div className="flex flex-col items-end gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">ÖSSZESEN****</p>
              <p className="text-left font-normal text-12 lg:text-14">370.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">415.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">445.600 Ft</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[40rem] m-auto">
        <p className="text-11 font-medium mt-8">
          * A kedvezmény visszavonásig érvényes, és a szolgálati igazolvány bemutatásával igényelhető.
        </p>
        <p className="text-11 font-medium">
          ** Mentős, rendőr, tűzoltó, katona, NAVos, BVs.
        </p>
        <p className="text-11 font-medium">
          *** A vizsgadíj taratlmazza a KRESZ és a forgalmi vizsga árát.
        </p>
        <p className="text-11 font-medium">
          **** Minimum forgalomban levezetett órákkal számolva (29 tanóra + 1 óra vizsga).
        </p>
        <p className="text-11 font-medium mt-4 lg:mt-8">A végösszegek esetében a gyakorlati oktatás díja a kötelező gyakorlati óraszám alapján kalkulált összeg. A szükséges plusz órák plusz költséggel járnak, melynek pontos díja a szükséges órák száma és a fenti táblázatban jelölt óradíjak alapján kalkulálhatók.</p>
        <p className="text-11 font-medium mt-2">
          Az árak az elsősegély tanfolyamot és a vizsga árát nem tartalmazzák.
        </p>
      </div>
      <h2 className="font-extrabold text-center text-15 lg:text-19 mt-6 2xl:mt-9 mb-6 ">Váci FKA-s kedvezmény*</h2>
      <h2 className="font-normal text-center text-13 lg:text-15 mb-6">Kedvezményes KRESZ oktatással támogatjuk a Váci FKA sportolóit, hogy könnyebben és gyorsabban megszerezhessék a jogosítványukat!</h2>
      <h3 className="font-normal text-center text-13 lg:text-15 mb-6">Kedvezmény mértéke: -20% a KRESZ tandíj teljes árából!</h3>
      <div className="relative w-full overflow-x-auto">
        <div className="min-w-[25rem]">
          <div className="flex flex-row justify-between w-full max-w-[40rem] m-auto gap-3 lg:gap-0">
            <div className="flex flex-col items-start gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">TÍPUS</p>
              <p className="text-left font-normal text-12 lg:text-14">„B” normál</p>
              <p className="text-left font-normal text-12 lg:text-14">„B” intenzív</p>
              <p className="text-left font-normal text-12 lg:text-14">„B” automata</p>
            </div>
            <div className="flex flex-col items-center gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">VIZSGADÍJ**</p>
              <p className="text-left font-normal text-12 lg:text-14">15.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">15.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">15.600 Ft</p>
            </div>
            <div className="flex flex-col items-center gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">KRESZ ELMÉLET</p>
              <p className="text-left font-normal text-12 lg:text-14">40.000 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">40.000 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">40.000 Ft</p>
            </div>
            <div className="flex flex-col items-center gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">GYAKORLATI DÍJ</p>
              <p className="text-left font-normal text-12 lg:text-14">10.500 Ft / óra</p>
              <p className="text-left font-normal text-12 lg:text-14">12.000 Ft / óra</p>
              <p className="text-left font-normal text-12 lg:text-14">13.000 Ft / óra</p>
            </div>
            <div className="flex flex-col items-end gap-4 min-w-fit">
              <p className="text-left font-extrabold text-12 lg:text-14">ÖSSZESEN***</p>
              <p className="text-left font-normal text-12 lg:text-14">370.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">415.600 Ft</p>
              <p className="text-left font-normal text-12 lg:text-14">445.600 Ft</p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[40rem] m-auto">
        <p className="text-11 font-medium mt-8">
          * A kedvezmény visszavonásig érvényes és a Váci FKA bármely korosztályú és bármely kategóriájú csapatában játszó tagra vonatkozik.
        </p>
        <p className="text-11 font-medium">
          ** A vizsgadíj taratlmazza a KRESZ és a forgalmi vizsga árát.
        </p>
        <p className="text-11 font-medium">
          *** Minimum forgalomban levezetett órákkal számolva (29 tanóra + 1 óra vizsga).
        </p>
        <p className="text-11 font-medium mt-4 lg:mt-8">A végösszegek esetében a gyakorlati oktatás díja a kötelező gyakorlati óraszám alapján kalkulált összeg. A szükséges plusz órák plusz költséggel járnak, melynek pontos díja a szükséges órák száma és a fenti táblázatban jelölt óradíjak alapján kalkulálhatók.</p>
        <p className="text-11 font-medium mt-2">
          Az árak az elsősegély tanfolyamot és a vizsga árát nem tartalmazzák.
        </p>
      </div>
    </section>
  );
}

export default Discounts;
